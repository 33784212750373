@import "./variables.scss";
@import "./animations/cloud.scss";

h5{
    margin: auto;
}
.template2 {
    height: 100vh;
    min-height: 600px;
    width: auto;
    position: relative;
    overflow: hidden;
    background: linear-gradient(to bottom , $background-color1, $background-color2);
}
.content-container2 {
    height: 100%;
    width: 90%;
    z-index: 9;
    position: absolute;
    justify-content: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    right: 0;
    left: 0;
    margin: auto;
}
.tab {
    display: inline-block;
    margin-left: 40px;
}
#name {
    height: 1.5em;
    width: 7em;
    min-width: 7em;
    position: absolute;
    top: 1em;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    text-align: center;
    font-size: $font-size;
    color: $white;
    border-radius: $border-radius-1;
    background: $title-color;

}
#title {
    height: 1.5em;
    width: 4em;
    min-width: 20em;
    z-index: 9;
    font-size: 1em;
    position: absolute;
    top: 9em;
    left: 15.1em; 
    right: 0; 
    margin: auto;
    background: $white;

}
.bio {
    height: 500px;
    align-self: center;
    background: $panel-color0;
    margin-left: 10px;
    width: 100%;
    max-width: 60vw;
    font-size: small;
}
.bio-head {
    margin: auto 0 0 0;
    font-size: 4em;
    background: $panel-color1;
}
.bio-text {
    margin: 5vh 3vw;
    height: 65%;
    font-family: "Gotham";
    letter-spacing: 0em;
    text-align: left;
    font-size: 12px;
    overflow-y: auto;
}
.avatar {
    height: 500px;
    width: auto;
    align-self: center;
}

@media (max-width: 775px){
    #name {
        height: 1.5em;
        font-size: $mobile-font-size;
        margin: auto auto;

    }
    #title {
        left: 3em;
        top: 6.5em;
        margin: auto auto;
    }
    .avatar {
        width: 340px;
        height: 510px;
        margin: 20vh auto 10vh auto;
    }
    .bio {
        max-width: 80vw;
        margin: 5vh auto 5vh auto;
        height: 60vh;
    }
    .bio-text {
        height: 70%;
    }
    .template2{
        height: 100vh;
        overflow-y: scroll;
    }
    .content-container2{
        flex-direction: column;
        height: fit-content;
    }
}