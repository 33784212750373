@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

$white: #ffffff;
$black: #000000;

$shadow-box: 0.5rem 0.5rem rgba(0, 0, 0, 0.693);
$background-color1: #b5c4ba;
$background-color2: #829790;
$background-color3: #d2d2d2;
$background-color4: #626a7d;
$background-color5: #fff7e2;

$title-color: #85b5a4;
$button-color: #b5c4ba;
$text-color: #a9acb4;
$divider-color: #ffa9a9;
$panel-color0: #e8d7bf;
$panel-color1: #f1e2cc; 
$panel-color2: #b5c4ba;

$Bodoni: "BodoniCyrillic";
$Gotham: "Gotham";

$font-size: 4em;
$mobile-font-size: 3em;
$border-radius-0: 0px;
$border-radius-1: 5px;
$border-radius-2: 15px;
$border-width-1: 10px;
$border-style-1: solid;
$transition-fade: 45%;

$cloud-color-0: #bcc9c0;
$cloud-color-1: #9db8a5;
$cloud-x1-motion: 30s;
$cloud-x2-motion: 36s;
$cloud-x3-motion: 49s;
$cloud-x4-motion: 65s;
$cloud-x5-motion: 70s;