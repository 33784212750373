@import "_variables.scss";
@import "_mixins.scss";

.navbar {
	background-color: $panel-color2;
	height: 50px;
	width: 100vw;
	display: flex;
	justify-content: flex-start;
}
a {
	color: black;
	height: 100%;
	width: 80px;
	text-decoration: none;
	text-align: center;
	vertical-align: middle;
	padding: 15px 12px;
}
a:hover {
	color: white;
	text-decoration: solid;
}
ol {
	width: auto;
	display:flex;
	justify-content: center;
	align-items: center;
	list-style-type: none;
}
.logo-container{
	padding: 0 10px 0 10px;
}
.App-logo {
	max-height: 100%;
	max-width: 100%;

}