@import "./_variables.scss";
.template3{
    height: 100vh;
    width: auto;
    position: relative;
    background: $background-color3;
}
.content-container3 {
    height: 100%;
    z-index: 9;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height:100vh;
    overflow-y: scroll;
}
iframe{
    border-radius: $border-radius-1;
    box-shadow: 0.5rem 0.5rem rgba(0, 0, 0, 0.693);
    height: 30vh;
    width: 40vw;
}
.header-title {
    height: 1.5em;
    width: 7em;
    min-width: 7em;
    position: absolute;
    top: 1em;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    text-align: center;
    font-size: $font-size;
    border-radius: $border-radius-1;
    background: $title-color;
    color: $white;
}

.header {
    margin: 5vh auto 5vh auto;
    min-width: 175px;
    height: 10vh;
}
.video-list-container{
    height: 100%;
}
.video-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.video-list>:nth-child(n) .description-box {
    padding: 10px 3vw;
}
.video-list>:nth-child(n) .description-text {
    margin:auto;
    white-space: break-spaces;
}
.video-list>:nth-child(n) .title-text {
    margin: auto;
    min-height: 17px;
    font-size: larger;
    font-weight:bolder
}
.video-list>:nth-child(even) .description-box {
    width: 60%;
    height: 100%;
    max-width: 800px;
    min-height: 210px;
    height: 100%;
    display:flex;
    flex-direction: column;
    margin-left: auto;
    border: $border-radius-0;
    background-color: $panel-color2;
}
.video-list>:nth-child(odd) .description-box {
    width: 60%;
    height: 100%;
    max-width: 800px;
    min-height: 210px;
    height: 100%;
    display:flex;
    flex-direction: column;
    margin-right: auto;
    border: $border-radius-0;
    background-color: $panel-color2;
}
.video-list>:nth-child(n) {
    padding: 0;
    display: flex;
    margin: 5%;
    width: 90%;
    justify-content: space-around;
    align-self: center;

}
.video-list>:nth-child(odd) {
    flex-direction: row;
}
.video-list>:nth-child(even) {
    flex-direction: row-reverse;
}

@media (max-width: 700px) {
    .video-list > :nth-child(n){
        flex-direction: column;
    }
    .video-list > :nth-child(n) .description-box{
        width: 100%;
        box-shadow: $shadow-box;
    }
    iframe {
        width: 100%;
    }
}