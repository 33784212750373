@import "./variables.scss";

.template5{
    height:100vh;
    width:100vw;
    position: absolute;
    background: $background-color5;
}
.container{
    height:100%;
    width:100%;
    position: fixed;
}
.contact-icon{
    height: auto;
    width: 500px;
    margin: 85px auto 0 auto;
}
.contact-form{
    display:flex;
    flex-direction:column;
    align-items: center;
    width:70%;
    margin: auto;
}
.contact-header{
    font-size: xx-large;
    font-weight: bolder;
    font-style: italic;
}
.first-layer{
    margin: 5% 0 10% 0;;
}
#input {
    background-color: transparent;
    color: #000000;
    width: 100%;
    margin: 3% 0 3% 0;
    border-bottom:solid #000000 1px;
}
.form-submit {
    background-color:$button-color !important;
    width: 55px;
    border-radius: $border-radius-2;
    font-family: "Gotham";
}
#input:focus {
    border: none;
}
::-webkit-input-placeholder {
    text-align: center;
  }
.form-text {
    background-color: transparent;
    color: #000000;
    border-left: solid #000000 1px;
    border-right: solid #000000 1px;
    border-top: solid #000000 1px;
    border-bottom: solid #000000 1px;
    width:100%;
    height:30vh;
    margin: 10px;
    #textBox{ z-index:1000; }
    padding: 5%;
}
