@import "./styles/_variables.scss";
@import "./styles/_mixins.scss";

.App {
  background-color: $white;
  color: $black;
  text-align: center;
  min-height: 100vh;
  flex-direction: column;
  font-size: calc(14px);
  overflow: hidden;
  font-family: $Bodoni;
  letter-spacing: 00px;
}
.App-body{
  justify-content: center;
  justify-items:center;
  
}
