@import "./variables.scss";
.blog{
    height:100%;
    display:flex;
    flex-direction: column;
    overflow-y: scroll;
}
time{
    color: orange;
    font-size: 24px;
}
.template4{
    height: 100vh;
    width: auto;
    position: relative;
    background: $background-color4;
}
.header{
    margin: 5vh auto 5vh auto;
    min-width: 175px;
    height: 10vh;
}
.container{
    height: 100%;
    width: 100%;
}
.blog-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 0px;
    max-height: 50vh;
}
.blog-img{
    width: 875px;
    flex-shrink: 100%;
    border-radius: 0 15em 15em 0;
}
.blog-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $text-color;
    height: 100%;
    width: 100%;
    padding: 5vw;
    overflow: hidden;
}
.blog-title{
    font-size: xx-large;
    font-weight: bold;
}
.blog-divider{
    height: 5px;
    width: 100px;
    margin: 15px auto;
    align-self: center;
    border-radius: $border-radius-1;
    background: $divider-color;
}

@media (max-width: 775px){
    .blog-container{
        flex-direction: column;
        max-height:75vh;
    }
    .blog-description{
        padding: 10px;
    }
    .blog-img{
        padding-right: 7%;
    }
}